import type * as React from "react"

export const invokeRef = <T>(ref: React.ForwardedRef<T>, current: T) => {
  if (typeof ref === "function") {
    ref(current)
  } else if (ref != null) {
    ref.current = current
  }
}

export const extendRef =
  <T>(...refs: React.ForwardedRef<T>[]): React.ForwardedRef<T> =>
  (current: T | null) => {
    for (const ref of refs) {
      invokeRef(ref, current)
    }
  }

export const extendPropsWithRef = <T>(
  { ref, ...props }: PropsWithRef<T>,
  ...refs: React.ForwardedRef<T>[]
) => ({
  ...props,
  ref: extendRef(ref, ...refs),
})

type PropsWithRef<T> = Record<any, any> & {
  ref: React.ForwardedRef<T>
}
