import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"

import { Await } from "@remix-run/react"
import { useCart } from "./cart-provider.tsx"

export function CartTrigger() {
  const { open, cart } = useCart()

  return (
    <React.Suspense>
      <Await resolve={cart}>
        {(c) =>
          c && c.totalQuantity > 0 ? (
            <div className="relative flex items-center">
              <button type="button" onClick={open}>
                <Icon.Bag className="size-6" />
              </button>

              <UI.Text
                size="xs"
                className="absolute -top-0 -right-1.5 select-none tabular-nums bg-background"
              >
                {c.totalQuantity}
              </UI.Text>
            </div>
          ) : null
        }
      </Await>
    </React.Suspense>
  )
}
