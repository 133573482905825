import * as UI from "@iyk/ui"
import * as React from "react"

import { toSentenceCase } from "@iyk/string"
import { Link } from "@remix-run/react"
import { cva, type VariantProps } from "class-variance-authority"
import { CartTrigger } from "../../components/shop/cart-trigger.tsx"
import { IYK_LOGO_URL } from "../../lib/constants.ts"
import { useLoginDialog } from "../../lib/ui/login-dialog-provider.tsx"
import { useEventListener } from "../hooks/use-event-listener.ts"
import { useIykUser } from "../hooks/use-iyk-user.ts"
import { useLang } from "../lang/use-lang.ts"
import { extendRef } from "../utils/ref.ts"
import { AccountDropdown } from "./account-dropdown.tsx"

// #region Context

const Context = React.createContext({ hiddenLoginAction: false })
const useContext = () => React.useContext(Context)

// #endregion

// #region Header

export const Header = React.forwardRef<HTMLElement, HeaderProps>(
  (
    {
      children,
      className,
      hiddenLoginAction = false,
      scrollThreshold = 20,
      variant,
      logoNavigateTo = "",
    },
    ref,
  ) => {
    const [isHidden, setIsHidden] = React.useState(false)
    const lastScrollTopPosition = React.useRef(0)
    const headerRef = React.useRef<HTMLElement | null>(null)

    // To avoid re-calculating the navbar height on every scroll
    const navbarHeight = React.useMemo(() => headerRef.current?.offsetHeight || 0, [])

    const handleScroll = React.useCallback(() => {
      const currentScrollTop = window.scrollY
      const lastScrollTop = lastScrollTopPosition.current

      // Ignore small scroll changes
      if (Math.abs(lastScrollTop - currentScrollTop) <= scrollThreshold) return

      const shouldHideHeader =
        currentScrollTop > lastScrollTop && currentScrollTop > navbarHeight
      const shouldShowHeader =
        currentScrollTop + window.innerHeight < document.documentElement.scrollHeight

      if (shouldHideHeader) {
        setIsHidden(true)
      } else if (shouldShowHeader) {
        setIsHidden(false)
      }

      lastScrollTopPosition.current = currentScrollTop
    }, [scrollThreshold, navbarHeight])

    useEventListener("scroll", handleScroll, undefined, { passive: true })

    return (
      <Context.Provider value={{ hiddenLoginAction }}>
        <header
          ref={extendRef(headerRef, ref)}
          className={classForHeader({ isHidden, variant, className })}
        >
          {children ?? (
            <Link to={logoNavigateTo ? logoNavigateTo : ""}>
              <HeaderLogo src={IYK_LOGO_URL} />
            </Link>
          )}
          <div className="flex items-center gap-3">
            <HeaderAuthController />
            <CartTrigger />
          </div>
        </header>
      </Context.Provider>
    )
  },
)

Header.displayName = "Header"

const classForHeader = cva(
  [
    "z-50 top-0 left-0 w-full h-[var(--header-height)] p-4",
    "flex item-center justify-between",
    "transition-[top] duration-300 ease-in-out",
  ],
  {
    variants: {
      isHidden: {
        // We do this because the -top-[var(--header-height)] is not working
        true: "top-[calc(var(--header-height)*-1)]",
      },
      variant: {
        sticky: "sticky bg-background",
        fixed: "fixed bg-transparent",
      },
    },
    defaultVariants: {
      variant: "sticky",
    },
  },
)

type HeaderProps = {
  children?: React.ReactNode
  className?: string
  hiddenLoginAction?: boolean
  scrollThreshold?: number
  logoNavigateTo?: string
} & VariantProps<typeof classForHeader>

// #endregion

// #region HeaderAuthController

const HeaderAuthController = () => {
  const { isSignedIn } = useIykUser()
  return isSignedIn ? <AccountDropdown /> : <Login />
}

// #endregion

// #region HeaderLogo

export const HeaderLogo = ({ className, ...props }: JSX.IntrinsicElements["img"]) => {
  const lang = useLang()

  return (
    <UI.Avatar
      className={classForHeaderLogo({ className })}
      alt={toSentenceCase(lang.terms.POWERED_BY) + " IYK"}
      {...props}
    />
  )
}

const classForHeaderLogo = cva(["size-7"])

// #endregion

// #region Login

const Login = () => {
  const { showLoginDialog } = useLoginDialog()
  const { hiddenLoginAction } = useContext()
  const lang = useLang()

  if (hiddenLoginAction) return null

  return (
    <UI.Button
      size="sm"
      variant="secondary"
      onClick={() => {
        showLoginDialog()
      }}
    >
      {toSentenceCase(lang.terms.LOGIN)}
    </UI.Button>
  )
}

// #endregion
